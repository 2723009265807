<template>
  <div>
    <el-button
      v-if="
        type == 'list' &&
        (item.baseProductType == 1 ||
          item.baseProductType == 7 ||
          item.baseProductType == 14 ||
          item.baseProductType == 15 ||
          item.baseProductType == 5 ||
          item.baseProductType == 6) &&
        (item.state == 6 ||
          item.state == 4 ||
          item.state == 5 ||
          (item.state == 3 &&
            (item.baseProductType == 5 ||
              item.baseProductType == 6 ||
              item.baseProductType == 14 ||
              item.baseProductType == 7)))
      "
      type="primary"
      @click="uploadImg(item)"
    >
      上传{{ getName(item.baseProductType) }}
    </el-button>
    <el-button
      v-if="item.state == 0 || item.state == 7"
      v-hasBtn="'GY-SERVICE-DISPATCH'"
      type="danger"
      class="bgec"
      @click="dispatch(item)"
      >派单</el-button
    >
    <el-button
      v-if="(queryType != 3 || type == 'detailSubOrder') && item.state == 1"
      v-hasBtn="'GY-SERVICE-REMIND'"
      type="primary"
      @click="remind(item)"
    >
      提醒接单
    </el-button>
    <el-button
      v-if="
        (queryType == 3 || (getUserId() == item.serviceUserId && queryType == 'queryType')) &&
        item.state == 1 &&
        type != 'detailSubOrder'
      "
      :loading="confirmLoading"
      type="primary"
      @click="confirm(item)"
    >
      确认接单
    </el-button>
    <el-button
      v-if="
        (queryType == 3 || (getUserId() == item.serviceUserId && queryType == 'queryType')) &&
        item.state == 1 &&
        type != 'detailSubOrder'
      "
      :loading="refuseLoading"
      @click="refuse(item)"
    >
      拒绝接单
    </el-button>
    <UploadPhotoPopups
      v-if="isUploadPhoto"
      type="1"
      popups-title="上传照片"
      :show-state="isUploadPhoto"
      :current-data="currentData"
      @hidePopups="isUploadPhoto = false"
    />
    <assignOrder
      v-if="assignOrderVisible"
      :title="typeName"
      :current-data="currentData"
      :dialog-visible.sync="assignOrderVisible"
    />

    <template v-if="item.state == 3 || item.state == 6">
      <!-- 摄影 -->
      <template v-if="item.baseServiceType == 1">
        <el-button
          v-if="serviceDto.subStatus == 10"
          class="el-btn"
          @click="completion('uncutPhotos', 'uncutPhotos')"
          >确认完成上传原片</el-button
        >
        <el-button
          v-if="serviceDto.subStatus == 10 && !(serviceDto.buttonV2Dto || {}).hideQrxxscypBtn"
          class="el-btn"
          @click="completion('uncutPhotos_offline', 'uncutPhotos')"
        >
          确认已线下上传原片
        </el-button>
      </template>

      <!-- 摄像 -->
      <template v-if="item.baseServiceType == 15">
        <el-button
          v-if="serviceDto.subStatusVideo == 26"
          class="el-btn"
          @click="completion('video', 'video')"
          >确认完成上传原视频</el-button
        >
        <el-button
          v-if="serviceDto.subStatusVideo == 26"
          class="el-btn"
          @click="completion('video_offline', 'video')"
          >确认已线下上传原视频</el-button
        >
      </template>

      <!-- 剪辑 -->
      <template v-if="item.baseServiceType == 14">
        <el-button v-if="serviceDto.subStatusVideo == 29" class="el-btn" @click="completion('clip')"
          >确认完成上传剪辑</el-button
        >
      </template>

      <!-- 初修 -->
      <template v-if="item.baseServiceType == 5">
        <el-button
          v-if="serviceDto.subStatus == 13"
          class="el-btn"
          @click="completion('firstRepair', 'firstRepair')"
          >确认完成上传初修</el-button
        >
        <el-button
          v-if="serviceDto.subStatus == 13 && !(serviceDto.buttonV2Dto || {}).hideQrxxsccxBtn"
          class="el-btn"
          @click="completion('firstRepair_offline', 'firstRepair')"
        >
          确认已线下上传初修
        </el-button>
      </template>

      <!-- 精修 -->
      <template v-if="item.baseServiceType === 6 && item.state === 3">
        <el-button class="el-btn" @click="completion('finedRepair')">确认完成上传精修</el-button>
      </template>

      <!-- 设计 -->
      <template v-if="item.baseServiceType == 7">
        <el-button v-if="serviceDto.subStatus == 21" class="el-btn" @click="completion('layout')"
          >确认完成上传设计版面</el-button
        >
      </template>

      <!-- 制作 -->
      <template v-if="item.baseServiceType == 8">
        <el-button v-if="serviceDto.subStatus == 24" class="el-btn" @click="completion('make')"
          >确认完成制作服务</el-button
        >
      </template>
    </template>
  </div>
</template>

<script>
import {
  notifyAccept,
  accept,
  refuse,
  start,
  finish,
  confirmUpload,
  confirmFinishMake,
  confirmViewSample,
  batchDispatchByProduct,
  getOrderDetail,
  getNexBaseProductType,
} from '@/api/order'
import UploadPhotoPopups from '@/views/orderMgt/orderList/components/uploadPhoto'
import assignOrder from './assignOrder.vue'
import { getUserId } from '@/utils/auth'

export default {
  components: {
    assignOrder,
    UploadPhotoPopups,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    queryType: {
      type: [Number, String],
      default: 1,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentData: {},
      isUploadPhoto: false,
      assignOrderVisible: false,
      typeName: '',
      baseProductType: '',
      confirmLoading: false,
      refuseLoading: false,
      btnPermissions: [],
      confirmButtonType: '',
    }
  },
  computed: {
    getName() {
      return (type) => {
        switch (type) {
          case 1:
            return '原片'
          case 5:
            return '初修照片'
          case 6:
            return '精修照片'
          case 7:
            return '设计版面照片'
          case 15:
            return '原视频'
          case 14:
            return '剪辑视频'
          default:
            break
        }
      }
    },
    serviceDto() {
      return this.item.serviceDto || {}
    },
  },
  mounted() {
    this.btnPermissions = JSON.parse(window.sessionStorage.getItem('btnPermissions'))
  },
  methods: {
    getUserId,
    uploadImg(row) {
      this.currentData = {
        ...row,
      }
      this.currentData.inType = 'list'
      this.isUploadPhoto = true
    },
    getTableList() {
      this.$emit('refresh')
    },
    start(row) {
      this.$confirm('是否确认开始服务？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          start({
            data: {
              serviceItemsId: row.serviceItemId,
            },
          }).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.$emit('refresh')
          })
        })
        .catch(() => {})
    },
    finish(row) {
      this.$confirm('是否确认完成服务？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          finish({
            data: {
              serviceItemsId: row.serviceItemId,
            },
          }).then(() => {
            this.$message({
              type: 'success',
              message: '操作成功!',
            })
            this.$emit('refresh')
          })
        })
        .catch(() => {})
    },
    dispatch(row) {
      this.currentData = {
        ...row,
      }
      this.baseProductType = row.baseProductType
      switch (row.baseProductType) {
        case 1:
          this.typeName = '摄影服务'
          break
        case 2:
          this.typeName = '化妆服务'
          break
        case 3:
          this.typeName = '美姿服务'
          break
        case 4:
          this.typeName = '造型服务'
          break
        case 5:
          this.typeName = '初修服务'
          break
        case 6:
          this.typeName = '精修服务'
          break
        case 7:
          this.typeName = '设计服务'
          break
        case 8:
          this.typeName = '制作服务'
          break
        case 14:
          this.typeName = '剪辑服务'
          break
        case 15:
          this.typeName = '摄像服务'
          break
        default:
          break
      }
      this.assignOrderVisible = true
    },
    remind(row) {
      this.$confirm('是否确认短信提醒服务人员接单？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          notifyAccept({
            data: row.serviceItemId,
          }).then(() => {
            this.$message({
              type: 'success',
              message: '提醒成功!',
            })
            this.$emit('refresh')
          })
        })
        .catch(() => {})
    },
    confirm(row) {
      this.$confirm('是否确认接单？', '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.confirmLoading = true
          accept({
            data: {
              serviceItemsId: row.serviceItemId,
            },
          })
            .then(() => {
              this.confirmLoading = false
              this.$message({
                type: 'success',
                message: '接单成功!',
              })
              this.$emit('refresh')
            })
            .catch(() => {
              this.confirmLoading = false
            })
        })
        .catch(() => {})
    },
    refuse(row) {
      this.$prompt('', '拒绝接单', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入拒绝接单理由',
        inputType: 'textarea',
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(({ value }) => {
          this.refuseLoading = true
          refuse({
            data: {
              serviceItemsId: row.serviceItemId,
              reason: value,
            },
          })
            .then(() => {
              this.refuseLoading = false
              this.$message({
                type: 'success',
                message: '拒绝成功',
              })
              this.$emit('refresh')
            })
            .catch(() => {
              this.refuseLoading = false
            })
        })
        .catch(() => {})
    },
    async completion(e, buttonType) {
      try {
        let tip = '完成确认'
        let content = ''
        let uploadType = 1
        let baseProductType = 1
        const row = this.serviceDto

        switch (e) {
          case 'firstRepair':
            tip = '完成上传初修确认'
            content =
              '是否确认完成上传初修？确认完成上传初修后，本服务订单所有的初修服务子订单都将完成服务。请确认本服务订单所有的初修服务子订单都已完成上传初修后再操作。'
            baseProductType = 5
            break
          case 'firstRepair_offline':
            tip = '线下上传初修确认'
            content =
              '是否确认已线下上传初修？确认已线下上传初修后，本服务订单所有的初修服务子订单都将完成服务。请确认本服务订单所有的初修服务子订单都已线下上传初修后再操作'
            uploadType = 2
            baseProductType = 5
            break
          case 'finedRepair':
            tip = '完成上传精修确认'
            content =
              '是否确认完成上传精修？确认完成上传精修后，本服务订单所有的精修服务子订单都将完成服务。请确认本服务订单所有的精修服务子订单都已完成上传精修后再操作'
            baseProductType = 6
            break
          case 'layout':
            tip = '完成上传设计版面确认'
            content =
              '是否确认完成上传设计版面？确认完成上传设计版面后，本服务订单所有的设计服务子订单都将完成服务。请确认本服务订单所有的设计服务子订单都已完成上传设计版面后再操作'
            baseProductType = 7
            break
          case 'make':
            tip = '完成制作服务确认'
            content =
              '是否确认完成制作服务？确认完成制作服务后，本服务订单所有的制作服务子订单都将完成服务。请确认本服务订单所有的制作服务子订单都已完成制作服务后再操作。'
            baseProductType = 8
            break
          case 'video':
            tip = '完成上传原视频确认'
            content =
              '是否确认完成上传原视频？确认完成上传原视频后，本服务订单所有的摄像服务子订单都将完成服务。请确认本服务订单所有的摄像服务子订单都已完成上传原视频后再操作。'
            baseProductType = 15
            break
          case 'video_offline':
            tip = '线下上传原视频确认'
            content =
              '是否确认已线下上传原视频？确认已线下上传原视频后，本服务订单所有的摄像服务子订单都将完成服务。请确认本服务订单所有的摄像服务子订单都已线下上传原视频后再操作。'
            uploadType = 2
            baseProductType = 15
            break
          case 'clip':
            tip = '完成上传剪辑确认'
            content =
              '是否确认完成上传剪辑？确认完成上传剪辑后，本服务订单所有的剪辑服务子订单都将完成服务。请确认本服务订单所有的剪辑服务子订单都已完成上传剪辑后再操作。'
            baseProductType = 14
            break
          case 'uncutPhotos':
            tip = '完成上传原片确认'
            content =
              '是否确认完成上传原片？确认完成上传原片后，本服务订单所有的摄影服务子订单都将完成服务。请确认本服务订单所有的摄影服务子订单都已完成上传原片后再操作。'
            baseProductType = 1
            break
          case 'uncutPhotos_offline':
            tip = '线下上传原片确认'
            content =
              '是否确认已线下上传原片？确认已线下上传原片后，本服务订单所有的摄影服务子订单都将完成服务。请确认本服务订单所有的摄影服务子订单都已线下上传原片后再操作。'
            uploadType = 2
            baseProductType = 1
            break
          default:
            break
        }

        this.$alert(
          `<div style='display: flex;align-items: center;font-size:16px'><i style='margin-right:4px;color:#E6A23C;font-size:24px' class='el-icon-warning'></i>${tip}</div><div style='color:#999;padding:0 28px'>${content}</div>`,
          '确认提示',
          {
            dangerouslyUseHTMLString: true,
            showCancelButton: true,
            beforeClose: async (action, instance, done) => {
              console.log('actionactionaction', action)
              try {
                if (action !== 'confirm') return
                instance.confirmButtonLoading = true
                instance.confirmButtonText = '执行中...'

                if (this.confirmButtonType === buttonType) {
                  this.$emit('refresh')
                  return
                }

                if (e === 'sample_offline') {
                  await confirmViewSample({
                    data: {
                      serviceId: row.serviceId,
                    },
                  })
                } else if (e === 'make') {
                  await confirmFinishMake({
                    data: row.serviceId,
                  })
                } else {
                  await confirmUpload({
                    data: {
                      serviceId: row.serviceId,
                      uploadType,
                      baseProductType,
                    },
                  })
                }

                this.confirmButtonType = buttonType
                const detailRes = await getOrderDetail({ data: row.serviceId })
                const detailInfo = detailRes.data || {}
                const nextBaseProductType = await this.getSendBaseProductType(detailInfo)

                const otherParams = {
                  orderId: detailInfo.orderId,
                  orderProductId: detailInfo.orderProductId,
                  orderProductIdPath: detailInfo.orderProductIdPath,
                  serviceId: detailInfo.serviceId,
                  baseProductType: nextBaseProductType,
                }

                // 如果下一阶段是确认线上，线下看样，确认取件，已上传剪辑跳过
                if (
                  (detailInfo.buttonV2Dto.qrxxkyBtn &&
                    (detailInfo.subStatus === 11 ||
                      detailInfo.subStatus === 14 ||
                      detailInfo.subStatus === 15)) ||
                  (detailInfo.buttonV2Dto.fskyBtn &&
                    (detailInfo.subStatus === 11 || detailInfo.subStatus === 14)) ||
                  detailInfo.buttonV2Dto.qrqjBtn ||
                  detailInfo.subStatusVideo === 30
                ) {
                  // 同时包含摄影，摄像的双条线订单，已上传剪辑之后，处理另一条线下一阶段订单
                  if (detailInfo.subStatusVideo === 30 && nextBaseProductType) {
                    await batchDispatchByProduct({ data: otherParams })
                  }

                  this.$message({ type: 'success', message: '确认成功!' })
                  this.$emit('refresh')
                  return
                }

                await batchDispatchByProduct({ data: otherParams })
                this.$message({ type: 'success', message: '确认成功!' })
                this.$$emit('refresh')
              } catch (err) {
                console.log(err)
              } finally {
                instance.confirmButtonLoading = false
                done()
              }
            },
          },
        )
      } catch (err) {
        console.log(err)
      }
    },
    async getSendBaseProductType(item) {
      const res = await getNexBaseProductType({
        data: item.serviceId,
      })
      return res.data
    },
  },
}
</script>

<style lang="less" scoped>
.el-btn {
  padding: 10px 4px;
}
</style>
