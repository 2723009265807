export const getBaseProductTypeName = function () {
  return (type) => {
    switch (type) {
      case 1:
        return '拍摄服务'
      case 2:
        return '化妆服务'
      case 3:
        return '美姿服务'
      case 4:
        return '造型服务'
      case 5:
        return '初修服务'
      case 6:
        return '精修服务'
      case 7:
        return '设计服务'
      case 8:
        return '制作服务'
      case 15:
        return '摄像服务'
      case 14:
        return '剪辑服务'
      default:
        return '--'
    }
  }
}
