<template>
  <div class="wrap">
    <el-dialog title="操作派单" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <span>请选择【{{ title }}】人员</span>
      <div class="box">
        <el-input v-model="form.data.keyword" placeholder="输入关键字搜索服务人员" class="input-with-select">
          <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
        <el-scrollbar ref="scroll" class="scrollBox" style="height: 300px">
          <el-table v-loading="loading" size="mini" :data="tableData" style="width: 100%">
            <el-table-column prop="avatarUrl" label="照片" width="108">
              <template slot-scope="scope">
                <el-image style="border-radius: 6px" lazy fit="fill" :src="scope.row.avatarUrl">
                  <span slot="error" class="el-image-error">
                    <span slot="error" class="el-image-error">暂无头像</span>
                  </span>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="姓名">
              <template slot-scope="scope">
                {{ scope.row.userName }}
              </template>
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号" width="150"></el-table-column>
            <el-table-column label="选择" width="65">
              <template slot-scope="scope">
                <el-radio v-model="radio" :label="scope.row.userId" @change.native="getCurrentRow(scope.row)">&emsp;</el-radio>
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
        <Pages :small="true" :current-page="form.pageNumber" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: unset; margin-right: 10px; margin-bottom: unset" @click="handleClose">取 消</el-button>
        <el-button :loading="btnLoading" :disabled="!radio" type="primary" style="width: unset" @click="ok">确认派单</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getUserListPost, dispatch } from '@/api/order'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      radio: '',
      form: {
        data: {},
        pageNumber: 1,
        pageSize: 10,
      },
      multipleSelection: {},
      btnLoading: false,
      loading: false,
      total: 0,
    }
  },
  computed: {
    getName() {
      return (type) => {
        switch (type) {
          case '1':
            return '原片'
          case '5':
            return '初修照片'
          case '6':
            return '精修照片'
          case '7':
            return '设计照片'
          case '15':
            return '原视频'
          case '14':
            return '剪辑视频'
          default:
            break
        }
      }
    },
  },

  mounted() {
    this.getUserListPost()
  },

  methods: {
    getUserListPost() {
      this.loading = true
      this.form.data.baseProductType = this.currentData.baseProductType
      this.form.data.serviceId = this.currentData.serviceId
      getUserListPost(this.form).then((res) => {
        this.radio = ''
        this.loading = false
        this.tableData = res.data.content
        this.total = Number(res.data.total)
      })
    },
    search() {
      this.form.pageNumber = 1
      this.getUserListPost()
    },
    async ok() {
      this.btnLoading = true
      console.log(this.multipleSelection)
      dispatch({
        data: {
          serviceItemsId: this.currentData.serviceItemId,
          serviceUserId: this.radio,
          serviceUserName: this.multipleSelection.userName,
        },
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '派单成功',
          })
          this.$parent.getTableList()
          this.handleClose()
          this.btnLoading = false
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    handleClose() {
      this.tableData = []
      this.radio = ''
      this.loading = false
      this.$emit('update:dialogVisible', false)
    },
    getCurrentRow(val) {
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.pageNumber = 1
      this.getUserListPost()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.getUserListPost()
    },
  },
}
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
// ::v-deep.el-dialog__footer {
//   text-align: right !important;
//   .el-button {
//     display: inline-block !important;
//     width: auto !important;
//     margin-right: 10px !important;
//   }
// }
::v-deep.el-image {
  width: 86px;
  height: 66px;
}
::v-deep.has-gutter tr .el-table__cell {
  background-color: #fff !important;
}
.box {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #eee;
}
// ::v-deep.el-input-group__append {
//   background-color: #fff;
//   border-left: none;
// }
::v-deep.el-scrollbar__wrap {
  overflow-x: hidden;
}
.loading {
  text-align: center;
  padding: 10px;
}
</style>
